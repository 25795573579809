export const ProgrammeTypeTexts = {
  ModelA: {
    title: 'Model A - Playgroups',
    subtitle: 'All franchisees that are playgroups must:',
    text: [
      '• run a minimum of two groups;',
      '• hold two three-hour sessions per week per group – four sessions in total each week;',
      '• have a minimum of five and maximum of twelve children registered for each group unless otherwise agreed;',
      '• be for children aged three and four years old, and only have older or younger children in exceptional circumstances;',
      '• ensure a Parent Assistant is present to help with any group with seven or more children;',
      '• organise one or two Parent Caregiver Meetings every month;',
      '• attend their SmartStart Club meeting every month;',
      '• if part of the Community Work Programme, meet the terms and conditions of their separate contract with the CWP.',
    ],
  },
  ModelB: {
    title: 'Model B - Day motthers',
    subtitle: 'All franchisees that are day mothers must:',
    text: [
      '• implement the SmartStart routine for at least 20 hours each week;',
      '• be for children aged three and four years old, and only have older or younger children in exceptional circumstances;',
      '• ensure that there is at least one adult for every six children;',
      '• organise one Parent Caregiver Meeting every month;',
      '• attend their SmartStart Club meeting every month;',
      '• understand their responsibilities to register with the Department of Social Development as a partial care facility if they have more than six children',
    ],
  },
  ModelC: {
    title: 'Model C - Preschools/ECD centres',
    subtitle: 'All franchisees that are ECD centres must:',

    text: [
      '• implement the SmartStart routine for at least 20 hours each week;',
      '• be responsible for classes for children aged three and four years old;',
      '• have no more than 20 children per class;',
      '• work with an assistant, and, when using the SmartStart routine, aim to ensure that there is at least one adult for every ten children;',
      '• organise one Caregivers Meeting every month;',
      '• attend their SmartStart Club meeting every month;',
      '• understand the Centre’s responsibilities to register with the Department of Social Development.',
    ],
  },
};
