export const visitTypes = {
  selfAssessment: {
    includes: 'self_assessment',
    first: {
      name: 'self_assessment_1',
      description: 'Self-assessment due',
    },
  },
  requestCoachingVisitOrCall: {
    name: 'request_coaching_visit_or_call',
    description: 'Request a coaching visit or call',
  },
};
