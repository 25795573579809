export const localeDropDownWrapper =
  'flex items-center justify-start px-4 py-2';
export const contentWrapper = 'fixed top-0 w-full h-full flex-col bg-uiBg z-10';

export const bannerContentWrapper =
  'flex flex-col justify-between items-stretch';
export const articleText = 'py-4 px-4 text-textDark';
export const bottom = 'pt-4 px-4';
export const closeButton = 'mt-4 mb-4 w-full';
export const languageLabel = 'font-medium font-body text-textLight pr-2 mt-1';
