import { Options } from '../index.types';

export const options: Options = {
  question1: [
    "0 - Adults did not allow any interaction with children during story time and did not invite children's contributions.",
    '1 - Adults allowed some interaction with children during story time, but there were only limited contributions from children.',
    "2 - Adults used oral storytelling and booksharing to create an interactive story-time that was full of conversation (for example, children were allowed to ask questions and adults paused to talk about the story and to invite children's responses).",
  ],
  question2: [
    '0 - Adults made no effort to explain new language and concepts during story time.',
    '1 - Adults explained some new language and concepts during story time, but it is likely that some children still struggled to understand.',
    '2 - Adults introduced and explained new language and concepts during story time.',
  ],
  question3: [
    '0 - Adults did not ask any questions during story time.',
    '1 - Adults tended to ask questions that invited one or two-word answers, and did not ask questions that helped children to think and reflect.',
    '2 - Adults used open-ended questions and comments during story time to enable children to practise thinking skills such as predicting and reasoning.',
  ],
  question4: [
    '0 - If using a storybook, adults did not point to the words or explain anything about print of books during story time.',
    '1 - If sharing a storybook, adults made a limited effort to help children become familiar with books or print (for example by pointing to a word or showing how to hold the book).',
    '2 - If sharing a storybook, adults did so in ways that helped children become familiar with books and print by (for example, point to letters, words and sentences, and showing that the book is read from left to right, explaining the cover).',
  ],
};
