import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, ThunkApiType } from '../types';
import {
  ActivityBeCreative,
  ActivityChildAttendance,
  ActivityHostFamilyDays,
  ActivityLeaveNoOneBehind,
  ActivityMeetRegular,
  Club,
  ClubLeader,
  ClubMeeting,
  ClubMember,
  ClubSupport,
  Coach,
  MutationAcceptNewClubLeaderRoleArgs,
  MutationChangeClubNameArgs,
  MutationSaveWelcomeMessageArgs,
  MutationUpdateCoachAboutInfoArgs,
  NewClubInput,
  NewClubMemberInput,
  QueryActivityBeCreativeDetailsArgs,
  QueryActivityChildProgressArgs,
  QueryActivityMeetRegularDetailsArgs,
  QueryClubForUserArgs,
} from '@ecdlink/graphql';
import { ClubService } from '@/services/ClubService';
import {
  BeCreativeActivityInput,
  ChangeClubSupportRoleInput,
  ClubMeetingInput,
  ActivityHostFamilyDetailsInput,
  NewClubLeaderInput,
  ActivityLeaveNoOneBehindDetailsInput,
  ActivityChildAttendanceDetailsInput,
  UpdateClubSupportStatusInput,
} from '@/services/ClubService/types';
import {
  ActivityChildProgressDto,
  DetailClubDto,
} from '@/models/club/club.dto';
import { LeagueClubsDto } from '@/models/club/league.dto';
import differenceInDays from 'date-fns/differenceInDays';

export const ClubActions = {
  GET_CLUBS_FOR_COACH: 'getClubsForCoach',
  GET_CLUB_BY_ID: 'getClubById',
  GET_CLUBS_MEMBERS: 'getClubsMembers',
  ADD_NEW_CLUB: 'addNewClub',
  ADD_NEW_CLUB_LEADER: 'addNewClubLeader',
  ADD_NEW_CLUB_MEMBERS: 'addNewClubMembers',
  MOVE_CLUB_MEMBERS: 'moveClubMembers',
  CHANGE_CLUB_NAME: 'changeClubName',
  UPDATE_COACH_ABOUT_INFO: 'updateCoachAboutInfo',
  GET_ACTIVITY_MEET_REGULAR_DETAILS: 'getActivityMeetRegularDetails',
  GET_ACTIVITY_BE_CREATIVE_DETAILS: 'getActivityBeCreativeDetails',
  GET_ACTIVITY_HOST_FAMILY_DETAILS: 'getActivityHostFamilyDetails',
  GET_ACTIVITY_LEAVE_NO_ONE_BEHIND_DETAILS:
    'getActivityLeaveNoOneBehindDetails',
  GET_ACTIVITY_CHILD_PROGRESS_DETAILS: 'getActivityChildProgressDetails',
  GET_ACTIVITY_CHILD_ATTENDANCE_DETAILS: 'getActivityChildAttendanceDetails',
  GET_CLUB_FOR_USER: 'getClubForUser',
  GET_LEAGUES_FOR_COACH: 'getLeaguesForCoach',
  GET_LEAGUE_FOR_USER: 'getLeagueForUser',
  SAVE_WELCOME_MESSAGE: 'saveWelcomeMessage',
  ACCEPT_NEW_CLUB_LEADER_ROLE: 'acceptNewClubLeaderRole',
  CHANGE_CLUB_SUPPORT_ROLE: 'changeClubSupportRole',
  ADD_CLUB_MEETING: 'addClubMeeting',
  ADD_CAREGIVER_REPORT_BACK_MEETING: 'addCaregiverReportBackMeeting',
  ADD_BE_CREATIVE_ACTIVITY: 'addBeCreativeActivity',
  ADD_FAMILY_DAY_MEETING: 'addFamilyDayMeeting',
  UPDATE_CLUB_SUPPORT_STATUS: 'updateClubSupportStatus',
  GET_CLUB_MEETINGS_WITH_MISSING_REGISTERS:
    'getClubMeetingsWithMissingRegisters',
  SET_CONTACT_CLUB_LEADER_STATUS_FOR_MEETING:
    'setContactClubLeaderStatusForMeeting',
};

export const getClubById = createAsyncThunk<
  DetailClubDto,
  { clubId: string },
  ThunkApiType<RootState>
>(
  ClubActions.GET_CLUB_BY_ID,
  async ({ clubId }, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).getClubById(clubId);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getClubsForCoach = createAsyncThunk<
  DetailClubDto[],
  { userId: string },
  ThunkApiType<RootState>
>(
  ClubActions.GET_CLUBS_FOR_COACH,
  async ({ userId }, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).getClubsForCoach(
          userId
        );
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getClubsMembers = createAsyncThunk<
  ClubMember[],
  { clubIds: string[] },
  ThunkApiType<RootState>
>(
  ClubActions.GET_CLUBS_MEMBERS,
  async ({ clubIds }, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    if (clubIds.length === 0) return [];

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).getClubsMembers(
          clubIds
        );
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addNewClubMembers = createAsyncThunk<
  boolean,
  { input: NewClubMemberInput },
  ThunkApiType<RootState>
>(
  ClubActions.ADD_NEW_CLUB_MEMBERS,
  async ({ input }, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).addNewClubMembers(
          input
        );
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const moveClubMembers = createAsyncThunk<
  boolean,
  { input: NewClubMemberInput },
  ThunkApiType<RootState>
>(
  ClubActions.MOVE_CLUB_MEMBERS,
  async ({ input }, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).moveClubMembers(
          input
        );
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addNewClub = createAsyncThunk<
  Club,
  { input: NewClubInput },
  ThunkApiType<RootState>
>(
  ClubActions.ADD_NEW_CLUB,
  async ({ input }, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).addNewClub(input);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addNewClubLeader = createAsyncThunk<
  ClubLeader,
  NewClubLeaderInput,
  ThunkApiType<RootState>
>(
  ClubActions.ADD_NEW_CLUB_LEADER,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).addNewClubLeader(
          input
        );
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const changeClubName = createAsyncThunk<
  Club,
  MutationChangeClubNameArgs,
  ThunkApiType<RootState>
>(
  ClubActions.CHANGE_CLUB_NAME,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).changeClubName(
          input
        );
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCoachAboutInfo = createAsyncThunk<
  Coach,
  MutationUpdateCoachAboutInfoArgs,
  ThunkApiType<RootState>
>(
  ClubActions.UPDATE_COACH_ABOUT_INFO,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).updateCoachAboutInfo(
          input
        );
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActivityMeetRegularDetails = createAsyncThunk<
  ActivityMeetRegular,
  { args: QueryActivityMeetRegularDetailsArgs; forceReload: boolean },
  ThunkApiType<RootState>
>(
  ClubActions.GET_ACTIVITY_MEET_REGULAR_DETAILS,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
      clubs: { clubsForCoach, clubForPractitioner },
    } = getState();

    try {
      if (!input.forceReload) {
        if (!!clubForPractitioner?.points?.meetRegularly?.data) {
          const daysSinceLoad = differenceInDays(
            new Date(),
            new Date(clubForPractitioner?.points?.meetRegularly?.dataLoaded!)
          );

          if (daysSinceLoad < 1) {
            return clubForPractitioner?.points?.meetRegularly?.data!;
          }
        }

        if (!!clubsForCoach[input.args.clubId]?.points?.meetRegularly?.data) {
          const daysSinceLoad = differenceInDays(
            new Date(),
            new Date(
              clubsForCoach[
                input.args.clubId
              ]?.points?.meetRegularly?.dataLoaded!
            )
          );

          if (daysSinceLoad < 1) {
            return clubsForCoach[input.args.clubId]?.points?.meetRegularly
              ?.data!;
          }
        }
      }

      if (userAuth?.auth_token) {
        return await new ClubService(
          userAuth?.auth_token
        ).getActivityMeetRegularDetails(input.args);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActivityBeCreativeDetails = createAsyncThunk<
  ActivityBeCreative,
  QueryActivityBeCreativeDetailsArgs,
  ThunkApiType<RootState>
>(
  ClubActions.GET_ACTIVITY_BE_CREATIVE_DETAILS,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(
          userAuth?.auth_token
        ).getActivityBeCreativeDetails(input);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActivityChildProgressDetails = createAsyncThunk<
  ActivityChildProgressDto,
  QueryActivityChildProgressArgs,
  ThunkApiType<RootState>
>(
  ClubActions.GET_ACTIVITY_CHILD_PROGRESS_DETAILS,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(
          userAuth?.auth_token
        ).getActivityChildProgressDetails(input);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActivityHostFamilyDetails = createAsyncThunk<
  ActivityHostFamilyDays,
  ActivityHostFamilyDetailsInput,
  ThunkApiType<RootState>
>(
  ClubActions.GET_ACTIVITY_HOST_FAMILY_DETAILS,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(
          userAuth?.auth_token
        ).getActivityHostFamilyDetails(input);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActivityLeaveNoOneBehindDetails = createAsyncThunk<
  ActivityLeaveNoOneBehind,
  ActivityLeaveNoOneBehindDetailsInput,
  ThunkApiType<RootState>
>(
  ClubActions.GET_ACTIVITY_LEAVE_NO_ONE_BEHIND_DETAILS,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(
          userAuth?.auth_token
        ).getActivityLeaveNoOneBehindDetails(input);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActivityChildAttendanceDetails = createAsyncThunk<
  ActivityChildAttendance,
  ActivityChildAttendanceDetailsInput,
  ThunkApiType<RootState>
>(
  ClubActions.GET_ACTIVITY_CHILD_ATTENDANCE_DETAILS,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(
          userAuth?.auth_token
        ).getActivityChildAttendanceDetails(input);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getClubForUser = createAsyncThunk<
  DetailClubDto,
  QueryClubForUserArgs,
  ThunkApiType<RootState>
>(
  ClubActions.GET_CLUB_FOR_USER,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).getClubForUser(
          input
        );
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLeaguesForCoach = createAsyncThunk<
  LeagueClubsDto[],
  { coachUserId: string },
  ThunkApiType<RootState>
>(
  ClubActions.GET_LEAGUES_FOR_COACH,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
      clubs: { leaguesForCoach, dateLeagueDataLoaded },
    } = getState();

    try {
      if (!!leaguesForCoach) {
        const daysSinceLoad = differenceInDays(
          new Date(),
          new Date(dateLeagueDataLoaded || 0)
        );

        if (daysSinceLoad < 1) {
          return leaguesForCoach;
        }
      }

      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).getLeaguesForCoach(
          input
        );
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLeagueForUser = createAsyncThunk<
  LeagueClubsDto,
  { userId: string },
  ThunkApiType<RootState>
>(
  ClubActions.GET_LEAGUE_FOR_USER,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
      clubs: { leagueForPractitioner, dateLeagueDataLoaded },
    } = getState();

    try {
      if (!!leagueForPractitioner) {
        const daysSinceLoad = differenceInDays(
          new Date(),
          new Date(dateLeagueDataLoaded || 0)
        );

        if (daysSinceLoad < 1) {
          return leagueForPractitioner;
        }
      }

      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).getLeagueForUser(
          input
        );
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveWelcomeMessage = createAsyncThunk<
  boolean,
  MutationSaveWelcomeMessageArgs,
  ThunkApiType<RootState>
>(
  ClubActions.SAVE_WELCOME_MESSAGE,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(userAuth?.auth_token).saveWelcomeMessage(
          input
        );
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const acceptNewClubLeaderRole = createAsyncThunk<
  boolean,
  MutationAcceptNewClubLeaderRoleArgs,
  ThunkApiType<RootState>
>(
  ClubActions.ACCEPT_NEW_CLUB_LEADER_ROLE,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(
          userAuth?.auth_token
        ).acceptNewClubLeaderRole(input);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const changeClubSupportRole = createAsyncThunk<
  // TODO: add type
  any,
  ChangeClubSupportRoleInput | undefined,
  ThunkApiType<RootState>
>(
  ClubActions.CHANGE_CLUB_SUPPORT_ROLE,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
      clubs: { clubForPractitioner },
    } = getState();

    let payload = input as ChangeClubSupportRoleInput;

    if (!input?.clubId) {
      payload = {
        clubId: clubForPractitioner?.club?.id ?? '',
        practitionerId:
          clubForPractitioner?.club?.clubSupport?.practitionerId ?? '',
      } as ChangeClubSupportRoleInput;
    }

    if (!payload?.practitionerId) return;

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(
          userAuth?.auth_token
        ).changeClubSupportRole(payload);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addCaregiverReportBackMeeting = createAsyncThunk<
  boolean[],
  { clubId: string; userId: string } | undefined,
  ThunkApiType<RootState>
>(
  ClubActions.ADD_CAREGIVER_REPORT_BACK_MEETING,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
      clubs: { addCaregiverReportBackMeetingSyncInput },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        if (!!input && !!input.clubId) {
          return [
            await new ClubService(
              userAuth?.auth_token
            ).addCaregiverReportBackMeeting(input.clubId, input.userId),
          ];
        }

        if (!!addCaregiverReportBackMeetingSyncInput) {
          return [
            await new ClubService(
              userAuth?.auth_token
            ).addCaregiverReportBackMeeting(
              addCaregiverReportBackMeetingSyncInput.clubId,
              addCaregiverReportBackMeetingSyncInput.userId
            ),
          ];
        }

        return [true];
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addClubMeeting = createAsyncThunk<
  // TODO: add type
  any,
  ClubMeetingInput | undefined,
  ThunkApiType<RootState>
>(
  ClubActions.ADD_CLUB_MEETING,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
      clubs: { addClubMeetingSyncInputs },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        if (!!input?.meetingDate) {
          return await new ClubService(userAuth?.auth_token).addClubMeeting(
            input
          );
        }
        if (!input?.meetingDate && addClubMeetingSyncInputs?.length) {
          const promises = addClubMeetingSyncInputs.map(
            (meeting: ClubMeetingInput) =>
              new ClubService(userAuth?.auth_token).addClubMeeting(meeting)
          );

          return await Promise.all(promises);
        }
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addBeCreativeActivity = createAsyncThunk<
  // TODO: add type
  any,
  BeCreativeActivityInput | undefined,
  ThunkApiType<RootState>
>(
  ClubActions.ADD_BE_CREATIVE_ACTIVITY,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
      clubs: { addBeCreativeActivitySyncInputs },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        if (!!input?.dateUploaded) {
          return await new ClubService(
            userAuth?.auth_token
          ).addBeCreativeActivity(input);
        }
        if (!input?.dateUploaded && addBeCreativeActivitySyncInputs?.length) {
          const promises = addBeCreativeActivitySyncInputs.map((activity) =>
            new ClubService(userAuth?.auth_token).addBeCreativeActivity(
              activity
            )
          );

          return await Promise.all(promises);
        }
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addFamilyDayMeeting = createAsyncThunk<
  // TODO: add type
  any,
  ClubMeetingInput | undefined,
  ThunkApiType<RootState>
>(
  ClubActions.ADD_FAMILY_DAY_MEETING,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
      clubs: { addFamilyDayMeetingSyncInputs },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        if (!!input?.meetingDate) {
          return await new ClubService(
            userAuth?.auth_token
          ).addFamilyDayMeeting(input);
        }
        if (!input?.meetingDate && addFamilyDayMeetingSyncInputs?.length) {
          const promises = addFamilyDayMeetingSyncInputs.map((activity) =>
            new ClubService(userAuth?.auth_token).addFamilyDayMeeting(activity)
          );

          return await Promise.all(promises);
        }
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateClubSupportStatus = createAsyncThunk<
  ClubSupport,
  UpdateClubSupportStatusInput,
  ThunkApiType<RootState>
>(
  ClubActions.UPDATE_CLUB_SUPPORT_STATUS,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(
          userAuth?.auth_token
        ).updateClubSupportStatus(input);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getClubMeetingsWithMissingRegisters = createAsyncThunk<
  ClubMeeting[],
  { clubId: string },
  ThunkApiType<RootState>
>(
  ClubActions.GET_CLUB_MEETINGS_WITH_MISSING_REGISTERS,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(
          userAuth?.auth_token
        ).getClubMeetingsWithMissingRegisters(input);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const setContactClubLeaderStatusForMeeting = createAsyncThunk<
  ClubMeeting,
  { clubMeetingId: string },
  ThunkApiType<RootState>
>(
  ClubActions.SET_CONTACT_CLUB_LEADER_STATUS_FOR_MEETING,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        return await new ClubService(
          userAuth?.auth_token
        ).setContactClubLeaderStatusForMeeting(input);
      } else {
        return rejectWithValue('no access token, profile check required');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
