export const noneOption = 'None';

export const options = [
  'Greeting time',
  'Message board',
  'Small group activity',
  'Planning time',
  'Play time',
  'Clean up time',
  'Recall time',
  'Large group activity',
  'Story time',
  'Outside time',
  noneOption,
];
