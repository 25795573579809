export const contentWrapper = 'h-full overflow-y-auto bg-white';

export const avatarWrapper = 'w-full inline-flex justify-center pt-8';
export const chipsWrapper =
  'w-full mt-10 flex flex-row justify-center items-center';
export const notificationsStacklist = 'bg-white mt-4 mb-4';
export const profileOptionsWrapper = 'w-full px-4 pb-4';
export const button = 'w-full mt-4';
export const buttonIcon = 'w-5 h-5 ml-2';

export const dialogContent = 'h-full overflow-y-hidden';
export const absentWrapper =
  'mt-6 w-11/12 h-24 flex items-center bg-alertBg justify-center mr-auto ml-auto rounded-lg';
export const absentWarning =
  'w-11/12 mt-6 mx-auto z-10 px-3.5 py-2.5 bg-white text-textMid rounded-lg bg-uiBg';
export const fadButton = 'm-3 bottom-14 z-10 px-3.5 py-2.5';
export const buttonIconStyle = 'h-5 w-5 text-primary mr-2';
export const infoWrapper =
  'mt-2 w-11/12 mx-auto flex justify-between items-center';
export const contactButtons = 'flex justify-center mt-4';
export const stackedList = 'pb-4 h-1/2 w-full';
export const wrapper = 'flex flex-row justify-start items-center p-4';
export const registeredChildrenCard =
  'mt-2 w-11/12 mx-auto flex justify-between items-center bg-uiMid';
export const attendanceCard = 'mt-4 w-11/12 mx-auto bg-uiBg';
export const perAgeCard = 'mt-4 mb-4 w-11/12 mx-auto bg-uiBg';
