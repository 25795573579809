import { Options } from '../index.types';

export const options: Options = {
  question1: [
    '0 - Adults were not warm and respectful when interacting with children.',
    '1 - Adults spoke and acted warmly and respectfully towards children some of the time, or only to some children.',
    "2 - Adults spoke and acted warmly and respectfully towards children, using appropriate methods (e.g. smile, hug, nod, calm voice, making eye contact, getting down to child's level, listening attentively).",
  ],
  question2: [
    '0 - Adults provided no children with individual attention.',
    '1 - Adults provided some but not all children with individual attention.',
    '2 - Adults offered positive, individual attention to all children.',
  ],
  question3: [
    "0 - Adults rarely noticed or acknowledge children's efforts and ideas, and tended to be critical.",
    "1 - Adults did not always notice or acknowledge children's efforts and ideas.  Adults gave some praisebut were also critical.",
    "2 - Adults acknowledge children's efforts and ideas (for example, by repeating their idea or describing what they had done), an offered encouragement and praise.",
  ],
  question4: [
    '0 - Adults did not attend to children who were upset.',
    '1 - Adults sometimes attended to children who were upset, or were slow to attend to upset children.',
    '2 - Adults always attended and offered appropriate comfort to children who were upset.',
  ],
  question5: [
    '0 - Adults used harsh and sometimes physical methods to maintain order, such as handling the children roughly or using unkind or rude language.',
    '1 - Adults sometimes used harsh words, a raised voice or confrontational methods to deal with disorder or poor behaviour.',
    '2 - Adults use appropriate and consistent methods to maintain order (such as diverting children, using a calm voice) and never used harsh or physical methods.',
  ],
  question6: [
    '0 - Adults imposed solutions to conflicts without consulting children, and punished children before talking to them properly.',
    '1 - Adults sometimes imposed solutions to conflicts and did not make enough effort to involve children, listen to their accounts and acknowledge their feelings and ideas.',
    '2 - Adults actively involved children in solving conflicts, by acknowledging their feelings, listening carefully to their accounts and trying their solutions.',
  ],
};
