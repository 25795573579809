export const localeDropDownWrapper =
  'w-full flex flex-row justify-start items-center';
export const contentWrapper = 'fixed top-0 w-full h-full flex-col bg-uiBg z-10';

export const bannerContentWrapper =
  'flex flex-col justify-between items-stretch';
export const articleText = 'py-4 px-4 text-uiMidDark';
export const bottom = 'pt-4 px-4';
export const closeButton = 'mt-4 mb-4 w-full';
export const paragraphStyle = 'text-base font-body mt-6 text-textMid';
export const boldText = 'font-bold';

export const dropdownStyles = 'w-11/12 left-4';
