export const contentWrapper =
  'flex h-full z-20 flex-col justify-start items-center px-2';
export const checkboxWrapper =
  'flex w-full flex-row justify-start items-center';
export const formStyle = 'pb-2 px-2';
export const wrapper = 'bg-uiBg h-full overflow-y-auto';
export const marginBottom = 'mb-4';
export const marginTop = 'mt-4';
export const listStyles = 'list-disc text-uiMidDark pl-5 mb-4 mt-4';
export const largeMarginTopSmallMarginBottom = 'mt-8 mb-4';
export const evenMarginTopAndBottom = 'mt-5 mb-5';
export const formButton = 'w-full mt-5 mb-5';
