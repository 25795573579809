export const questions = [
  {
    title: 'Display the daily routine:',
    subTitle:
      'is the daily routine displayed at the right height for children to reach it? Is there a marker that can be moved to show where they are?',
  },
  {
    title: 'Use the daily routine:',
    subTitle:
      'is the SmartStarter running the programme according to the daily routine?',
  },
  {
    title: 'Use the message board:',
    subTitle:
      'if you saw this, did the SmartStarter use the message board to give children information about the day?',
  },
  {
    title: 'Unpack the playkit:',
    subTitle:
      'is the playkit (or toys) unpacked in a way that children can reach and play with the toys and materials?',
  },
  {
    title: 'Set up different interest areas: ',
    subTitle:
      'are the different interest areas set up with the area labels (art, pretend, building, toys and games, story)?',
  },
  {
    title: 'Participate in play:',
    subTitle:
      'does the SmartStarter participate in the child’s play and get on their level?',
  },
  {
    title: 'Talk in a warm, positive way:',
    subTitle:
      'does the SmartStarter talk to the children in a warm, positive way?',
  },
  {
    title: 'Giving children choice:',
    subTitle:
      'does the SmartStarter give the children choice in what to play with and how to play with it? ',
  },
  {
    title: 'Supervising children:',
    subTitle:
      'did the SmartStarter make sure children were supervised at all times while you were there?',
  },
  {
    title: 'Interesting learning space:',
    subTitle:
      'is the learning space interesting, with child pictures and posters displayed?',
  },
  {
    title: 'Caring for children:',
    subTitle:
      'did the SmartStarter make children feel loved and comfort them when upset?',
  },
  {
    title: 'Encouraging talking:',
    subTitle:
      'did the SmartStarter chat to the children and encourage conversation through questions? ',
  },
  {
    title: 'Story time:',
    subTitle:
      'if you saw story time, did the SmartStarter share a story (book or oral) and encourage children to participate with questions and talking?',
  },
  {
    title: 'Free play:',
    subTitle:
      'if you were there at free play time, did the SmartStarter give children at least 45 minutes to play and choose freely?',
  },
];
