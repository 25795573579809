export const BasicInfoItems = {
  ProfilhePhoto: 'Profile photo',
  Role: 'Role',
  Province: 'Province',
};

export const ConnectionsTypes = {
  Connected: 'Connected',
  ReceivedRequests: 'Received requests',
  SentRequests: 'Sent requests',
};
