export const container =
  'min-h-full bg-white flex flex-1 flex-col bg-uiBg overflow-y-scroll';

export const labelContainer =
  'flex flex 1 flex-row justify-between items-center mb-6 pt-2';

export const signatureCanvas =
  'w-full bg-white h-60 rounded mb-4 mt-4 border border-textDark';

export const signaturePreview = 'w-1/3 rounded m-auto bg-white mt-4 mb-4';

export const button = 'w-full mt-1 mb-2';

export const icon = 'mr-2 text-white w-5';

export const clearIcon = 'mr-2 text-primary w-5';
