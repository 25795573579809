export const options = [
  'Adults speak and act warmly & respectfully to children.',
  'Adults offer individual attention to children, and give encouragement.',
  'Adults use calm and appropriate methods to keep order, and do not use harsh words, a raised voice or physical methods.',
  'Children are comforted if they are upset.',
  'Children are involved in solving conflicts.',
  'Adults create opportunities to talk with children, and listen and respond to their questions and ideas.',
  'Adults encourage children to make their own choices during the session and to do things for themselves where they can.',
  'Adults join in as a partner in children’s play, encourage children to talk about what they are doing, and use comments and questions to help children learn.',
  'Adults use appropriate activities and materials for the different ages and stages of children.',
  'During story time there is lots of conversation and children are encouraged to take part and ask questions.',
];

export const detailTexts = [
  'How/when do you make sure that children receive some individual attention?',
  'What methods do you use to keep order in your programme?',
  'What do you do when children become upset?',
  'How do you help children to solve conflicts? How do you involve children?',
  'When do you create opportunities to talk with children? How do you model good listening during the session?',
  'What opportunities are there for children to make choices and how do you support them?',
  'How do you join in children’s play? What sorts of things do you say or ask when you play with children, which helps them to learn and find out new things?',
  'How do you make sure that children of different ages and with different abilities are able to join in all of the activities?',
  'What methods do you use to encourage conversation during story time? What are some of the open-ended questions that you ask about stories?',
];
