import { Options } from '../index.types';

export const options: Options = {
  question1: [
    '0 - Adults told most children what to do during free play.',
    '1 - Only some children were allowed to make their own choices during free play and other children were told what to do by adults.',
    '2 - All children were allowed to make their own choices during free play and give support to do so when needed.',
  ],
  question2: [
    "0 - Adults made little or no effort to make available toys and materials that stimulated children's imagination during play, and to support them to use them.",
    "1 - Adults made available some toys and materials that stimulated children's imagination and supported some children to use them, but there was not a sufficient range of resources to engage all children.",
    "2 - Adults facilitated children's play by making available toys and materials that stimulated children's imagination and by supporting them to use them when needed.",
  ],
  question3: [
    "0 - Adults did not participate in children's play, and only supervised from the side.",
    "1 - Adults sometimes participated in children's play but were not involved for all of the time or only played with a few children.",
    "2 - Adults participated as partners in children's self-initiated play, and tried to play with different children.",
  ],
  question4: [
    "0 - Adults did not try to build children's understanding during free play.",
    "1 - Adults used appropriate techniques to build children's understanding some for the time and with some children.",
    "2 - Throughout free play, adults added information and used observations, questions and modelling to expand on children's ideas and build new understanding.",
  ],
  question5: [
    '0 - Adults made children participate in activities that were significantly too easy or too hard for them.',
    '1 - Adults did not always seem aware what level of activity or game was appropriate for the child.',
    '2 - Adults allowed children to play and learn at a level and pace which was appropriate for them.',
  ],
};
