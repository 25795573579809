export const wrapper = 'bg-white overflow-hidden';
export const content = 'px-4 pb-3';
export const text = 'mb-3';
export const linkText = 'flex flex-1 flex-row items-center cursor-hover';
export const icon = 'h-4 w-4 text-primary ml-2';
export const quickSearchWrapper =
  'w-full flex flex-row items-center px-5 py-1 bg-uiBg overflow-x-auto';
export const iconFill = 'w-6 text-primary cursor-pointer';
export const dropdownStyles = 'w-11/12 left-4 -mt-10';
export const searchIconWrapper =
  'inline-flex items-center p-2 cursor-pointer rounded-full round bg-white border-2 border-primary mr-5';
export const searchTextWrapper =
  'flex flex-row items-center justify-between px-4 h-16';
export const searchInput =
  'outline-none block w-full sm:text-sm text-black py-2 px-4 bg-white';
export const overlay = 'flex-1 h-full bg-white px-4 pt-4';
export const stackedList = 'flex flex-col gap-1 pb-180';
export const fadButton = 'm-3 absolute bottom-6 right-0 z-10 px-3.5 py-2.5';
export const dialogContent = 'h-full overflow-y-hidden';
export const dialogContentStackedList = 'pb-180';
