import { Options } from '../../coach-self-assessment.types';

export const options: Options = {
  question1: ['0 - Sometimes', '1 - Most of the time.', '2 - All the time.'],
  question2: ['0 - Sometimes', '1 - Most of the time.', '2 - All the time.'],
  question3: ['0 - Sometimes', '1 - Most of the time.', '2 - All the time.'],
  question4: ['0 - Sometimes', '1 - Most of the time.', '2 - All the time.'],
  question5: ['0 - Sometimes', '1 - Most of the time.', '2 - All the time.'],
  question6: ['0 - Sometimes', '1 - Most of the time.', '2 - All the time.'],
};
