export const ResourcesNames = {
  activities: 'Activities',
  stories: 'Stories',
  teachingTips: 'Teaching tips',
  other: 'Other',
};

export const ResourcesIcons = {
  activities: 'PuzzleIcon',
  stories: 'BookOpenIcon',
  teachingTips: 'LightBulbIcon',
  other: 'ViewGridIcon',
};

export const DataType = {
  dataFree: 'Data free',
  notDataFree: 'Not Data Free',
};

export const DataSortFilterOption = {
  mostLiked: 'Most liked',
  newest: 'Newest',
  oldest: 'Oldest',
  title: 'Title',
};

export const LikedFilterOption = {
  liked: 'Liked',
  notLiked: 'Not liked',
};
