export const bannerContent = 'px-4 z-10';
export const welcomeText = 'w-full py-30 break-words';
export const wrapper =
  'w-full flex flex-col justify-between items-start mb-4 md:mb-0';
export const classRoomIcon = 'bg-secondary text-white';
export const businessIcon = 'bg-warning text-white';
export const practitionerIcon = 'bg-quatenary text-white';
export const trainingIcon = 'bg-tertiary text-white';
export const communityIcon = 'bg-quatenary text-white';
export const icon = 'bg-tertiary text-white';

export const communityIconName = 'UserGroupIcon';
export const messagesIconName = 'BellIcon';
export const calendarIconName = 'CalendarIcon';
export const classroomIconName = 'AcademicCapIcon';
export const logoutIconName = 'ExternalLinkIcon';
export const pointsIconName = 'EmojiHappyIcon';
export const businessIconName = 'CashIcon';
export const profileIconName = 'UserIcon';
export const trainingIconName = 'BookOpenIcon';
