export enum LeagueType {
  Purple = 'Purple',
  NewStars = 'New Stars',
  RisingStars = 'Rising Stars',
  Other = 'Other',
}

export const daysToAcceptBeingLeader = 14;

export const maxCharactersInClubName = 35;

export const MIN_RISING_STARS_POINTS = 1600;
export const MAX_RISING_STARS_POINTS = 2000;
export const MIN_PURPLE_CLUB_POINTS = 1760;
export const MAX_PURPLE_CLUB_POINTS = 2200;

export const MAX_MEMBERS_IN_CLUB = 17;
export const MIN_MEMBERS_IN_CLUB = 4;

export const enum ClubActivities {
  BeCreative = 'Be creative',
  CaptureChildAttendance = 'Capture child attendance',
  CompleteChildProgressReports = 'Complete child progress reports',
  HostFamilyDays = 'Host family days',
  LeaveNoOneBehind = 'Leave no one behind',
  MeetRegularly = 'Meet regularly',
}

export const ClubActivitiesPointsPerLeague = {
  MeetRegularly: {
    All: { max: 800, green: 600, amber: 599, red: 0 },
  },
  BeCreative: {
    All: { max: 800, green: 600, amber: 599, red: 0 },
  },
  HostFamilyDays: {
    All: { max: 300, green: 225, amber: 224, red: 0 },
  },
  CompleteChildProgressReports: {
    All: { max: 200, green: 150, amber: 149, red: 0 },
  },
  LeaveNoOneBehind: {
    All: { max: 100, green: 75, amber: 74, red: 0 },
  },
  ChildAttendance: {
    All: { max: 800, green: 600, amber: 599, red: 0 },
  },
};

export const enum IssuesTasks {
  noClubLeader = 'No club leader',
  contactClubLeader = 'Contact club leader',
  contactClubLeaderName = 'Contact ',
  chooseClubLeader = 'Choose a new club leader',
  assignClubLeader = 'Assign club leader',
  notAcceptedClubLeader = 'Club leader has not accepted agreement',
  notEnoughClubMembers = 'Not enough club members',
  contact_club_members = 'Contact club members',
  addMembers = 'Add members',
  tooManyClubMembers = 'Too many club members',
  createClub = 'Create an additional club',
  clubLeaderMonths = ' has been a club leader for 6 or more months',
  missingRegister = 'Missing club meeting register',
  missingRegisterForMonth = ' club meeting register',
  clubAttendance = '% club attendance in ',
  coachAttendFirstMeeting = ', Attend first club meeting',
  coachMeetingAttended = ', Attend club meeting',
}
