export const label =
  'block text-base leading-snug font-body font-semibold text-textDark mb-2';
export const input =
  'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm';
export const inputGroup = 'ml-3 mr-3 mt-1';
export const button = 'w-full mt-1 mb-2';
export const icon = 'mr-2 text-white w-5';
export const iconPrimary = 'mr-2 text-primary w-5';
export const hintStyle = 'block mt-1 text-sm font-body text-textMid';
export const buttonIcon = 'h-4 w-4 text-primary ml-1';
export const divider = 'mt-4';
