import { Options } from '../index.types';

export const options: Options = {
  question1: [
    'The venue offers children enough space to play freely (about one square metre per child).',
    'If children use an outdoor area, it is fenced with a lockable gate.',
    'There is a list of emergency numbers visible on the wall.',
    'The venue has good natural ventilation (windows or doors that can open).',
    'The programme does not exceed the maximum child number per programme type.',
  ],
};
