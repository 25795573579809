import { Options } from '../index.types';

export const options: Options = {
  question1: [
    '0 - Children were often left unsupervised (3 times or more in a session).',
    '1 - Children were usually supervised (unsupervised 2 times or less in a session).',
    '2 - Children were supervised at all times.',
  ],
  question2: [
    '0 - The space was not divided into interest areas.',
    '1 - The space was divided into two interest areas.',
    '2 - The space was divided into 3 or more interest areas.',
  ],
  question3: [
    '0 - The play kit was not unpacked into the learning space.',
    "1 - The play kit was only unpacked and available for children's use for a short time (less than 40 mins of the session).",
    "2 - The play kit was unpacked and the materials were available for children's play for more than 40 mins of the session.",
  ],
  question4: [
    '0 - Interest areas and materials were not labelled.',
    '1 - Some interest areas and play materials were labelled.',
    '2 - All interest areas and play materials were labelled.',
  ],
  question5: [
    "0 - Toys and storybooks were kept out of children's reach.",
    '1 - Children could reach and get out some toys and storybooks without adult help.',
    '2 - Children could reach and get out most of the toys and storybooks without adult help.',
  ],
  question6: [
    "0 - There were not posters or examples of children's work displayed.",
    "1 - There were a few posters or examples of children's work displayed (fewer than 5).",
    "2 - There were lots of posters or examples of children's work displayed (5 or more total).",
  ],
};
