export enum ProgrammeType {
  Playgroup = 1,
  Daymother = 2,
  PreSchool = 3,
}

export const NoPlaygroupClassroomType = {
  name: 'Unsure',
  title: 'Unsure',
};
