export enum DailyRoutineItemType {
  smallGroup = 'Small group',
  largeGroup = 'Large group',
  messageBoard = 'Message board',
  freePlay = 'Free play',
  storyBook = 'Story book',
  greeting = 'Greeting & message board',
}

export enum StoryBookTypes {
  storyBook = 'Story book',
  readAloud = 'Read aloud',
  other = 'Other',
}
