export const contentWrapper = 'h-full overflow-y-auto';

export const avatarWrapper = 'w-full inline-flex justify-center pt-8';
export const chipsWrapper =
  'w-full mt-2.5 flex flex-row justify-center items-center mb-5';
export const notificationsStackList = 'bg-white my-4 px-4 flex flex-col gap-4';
export const profileOptionsWrapper = 'w-full px-4 pb-4';
export const button = 'w-full mt-4 shadow-button';
export const buttonIcon = 'w-5 h-5 mr-2';

export const dialogContent = 'h-full overflow-y-hidden';
