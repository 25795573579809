export const visitOrCallQuestion =
  'Did you visit the practitioner’s site, or did you have a support phone call?';
export const callAnswer = 'Call';
export const visitAnswer = 'Visit';
export const pqaFollowUpQuestion =
  'Is {client} ready for a follow-up PQA observation visit?';
export const reAccreditationFollowUpQuestion =
  'Is {client} ready for a follow-up reaccreditation visit?';
export const supportVisitSharedQuestion = 'What next steps did you agree on?';
export const supportVisitQuestion2 = 'The focus of this coaching visit was:';
