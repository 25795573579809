export const bannerContent = 'px-4';
export const wrapper =
  'w-full rounded-lg drop-shadow pt-4 flex flex-col justify-between items-start bg-white shadow';
export const smallTextWrapper =
  'flex flex-row items-center justify-start w-full h-10';
export const textItem = 'mb-2 px-4';
export const smallTextIcon = 'h-5 w-5 ml-1';
export const classRoomIcon = 'bg-tertiary text-white';
export const businessIcon = 'bg-uiLight text';
export const dialogContent = 'h-full';
