export const ResourcesNames = {
  financial: 'Financial',
  administration: 'Administration & policies',
  dbe: 'DBE registration',
  other: 'Other',
};

export const ResourcesIcons = {
  financial: 'ClipboardListIcon',
  administration: 'SpeakerphoneIcon',
  dbe: 'ShieldCheckIcon',
  other: 'ViewGridIcon',
};

export const DataType = {
  dataFree: 'Data free',
  notDataFree: 'Not Data Free',
};

export const DataSortFilterOption = {
  mostLiked: 'Most liked',
  newest: 'Newest',
  oldest: 'Oldest',
  title: 'Title',
};

export const LikedFilterOption = {
  liked: 'Liked',
  notLiked: 'Not liked',
};
