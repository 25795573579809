import { Options } from '../index.types';

export const options: Options = {
  question1: [
    '0 - Adults rarely talked to children and did not create opportunities for conversation.  Adults mostly talked to children to give instructions and maintain order.',
    '1 - There was some adult-child conversation and some activities were used to encourage children to communicate.',
    '2 - Adults talked frequently with children throughout the session for varied purposes, and created lots of opportunities for conversations.',
  ],
  question2: [
    "0 - Adults often ignored children's questions and comments and did not listen carefully to children.",
    "1 - Adults responded to children's comments and questions only some of the time.  Adults did not always listen carefully to children.",
    "2 - Adults responded respectfully to children's comments and questions, and listened carefully to children.",
  ],
  question3: [
    '0 - Adults did not use comments and questions to encourage children to talk about what they were doing to extend their learning.',
    '1 - Adults sometimes encouraged children to talk about what they were doing, but missed opportunities to prompt children to explain their thinking and reasoning.',
    '2 - Adults encouraged children to talk about what they were doing made appropriate use of questions and comments to prompt children to explain their thinking and reasoning.',
  ],
  question4: [
    "0 - Adults did not try to build children's language.",
    "1 - Adults sometimes built children's language through suggesting and explaining new words.",
    "2 - Adults frequently used appropriate methods to build children's language, such as introducing or explaining new words or repeating something a child had said using the correct language.",
  ],
  question5: [
    '0 - Adults told children how to carry out activities or use materials and did not let them make choices or do things for themselves.',
    '1 - Adults sometimes encouraged children to make their own choices and to do things for themselves (such as put on their shoes or pour water).',
    '2 - Adults often encouraged children to make choices about how to use materials and carry out activities.  Adults allowed children to do thing for themselves where developmentally appropriate.',
  ],
};
