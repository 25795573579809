export enum ProgressSkillValues {
  Yes = 'Yes',
  No = 'No',
  DoNotKnow = "Don't know",
}

export const ProgressSkillValuesArray = [
  ProgressSkillValues.Yes,
  ProgressSkillValues.No,
  ProgressSkillValues.DoNotKnow,
];
