export const options = [
  'Supervision: children are supervised at all times.',
  'Learning space: the space is divided into 3 or more interest areas, which are labelled.',
  'Using the toy kit: the play kit is unpacked and children can reach toys and story books.',
  'Displays: the learning space is interesting with posters and children’s work on the walls.',
  'SmartStart routine: the SmartStart routine is displayed at a height that children can reach.',
  'SmartStart activities: all the activities in the SmartStart routine (below) were included today.',
  'Free play: at least 45 minutes was set aside for free play.',
  'Planning & recall: children had the chance to plan and recall activities before and after free play.',
  'Small group time: there was small group time (at least 15 minutes).',
  'Story time: there was story time (at least 20 minutes).',
  'Large group time: there was large group time (at least 15 minutes).',
  'Message board: the message board is up to date.',
];

export const detailTexts = [
  'Can I see your plan for today?',
  'How long will free play be today?',
  'How do you help children to plan/recall before/after free play?',
  'What is your small group activity today?',
  'How long will story time be today?\nWhat story are you sharing today?',
  'What is your large group activity today?',
  'Can I see your message board for today?',
];
