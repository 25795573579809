export const options = [
  'Logging in to my account',
  'Registering/creating a new account',
  'Joining or adding my preschool',
  'Classroom',
  'Business',
  'Training',
  'Other',
];

export const phoneNumberOrEmailOptions = [
  { text: 'Phone', value: true },
  { text: 'Email', value: false },
];
