export const wrapper =
  'pt-2 pl-4 pr-2 flex flex-col w-full justify-evenly relative items-center pb-1 bg-successMain rounded bg-transparent';
export const textWrapper = 'px-4 flex flex-1 flex-col';
export const button = 'mb-4 mt-4';

export const closeWrapper = 'flex items-top mb-2 pr-1';

export const iconRound = 'rounded-full p-3 bg-successDark';
export const contentWrapper =
  'w-full flex flex-row justify-start items-center py-2';
