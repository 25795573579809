import { Options } from '../index.types';

export const options: Options = {
  question1: [
    'I agree to take the actions described in the box above in order to meet & maintain all SmartSpace standards.',
    'I understand that the Club Coach will visit again within 2 weeks to make sure changes have been made and that my Practice Licence may be withdrawn if they have not.',
    'I understand that I cannot have more children in the programme than my space can accommodate.',
  ],
  question1FromNotPass: [
    'I agree to take the actions described in the box above in order to meet & maintain all SmartSpace standards.',
    'I understand that the Club Coach will visit again within 2 weeks to make sure changes have been made and that my Practice Licence may be withdrawn if they have not.',
    'I understand I should not have more than 20 children in my class. I understand that the law says that I must not have more than this number.',
    'I understand that I cannot have more children in the programme than my space can accommodate.',
  ],
};
