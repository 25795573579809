import addPrefix from './withParentPrefix';

const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  OA_LOGIN: 'oa-login',
  OA_SIGN_UP_OR_LOGIN: '/oa-sign-up-or-login',
  LOGOUT: '/logout',
  PASSWORD_RESET: '/password-reset',
  NEW_PASSWORD: '/new-password',
  SIGN_UP: '/sign-up',
  CREATE_USERNAME: '/create-username',
  VERIFY_PHONE: '/verify-phone',
  CHILD_REGISTRATION_LANDING: '/child-registration-landing',
  PROGRAMMES: addPrefix('/programmes', {
    THEME: '/theme',
    TIMING: '/timing',
    SUMMARY: '/summary',
    ROUTINE: '/routine',
  }),
  ATTENDANCE_TUTORIAL_WALKTHROUGH: '/attendance-tutorial-walkthrough',
  DASHBOARD: '/dashboard',
  BUSINESS: '/business',
  BUSINESS_ADD_AMOUNT: '/business-add-amount',
  BUSINESS_ADD_INCOME: '/business-add-income',
  BUSINESS_UPDATE_INCOME: '/business-update-income',
  BUSINESS_ADD_EXPENSE: '/business-add-expense',
  BUSINESS_UPDATE_EXPENSE: '/business-update-expense',
  BUSINESS_PREVIOUS_STATEMENTS_LIST: '/business-previous-statements-list',
  BUSINESS_MONTH_STATEMENTS_DETAILS: '/month-statements-details',
  CALENDAR: '/calendar',
  CLASSROOM: addPrefix('/classroom', {
    CHILDREN: '/children',
    ATTENDANCE: addPrefix('/attendance', {
      EDIT_REGISTERS: '/edit-registers',
      MONTHLY_REPORT: '/monthly-report',
    }),
    ACTIVITIES: addPrefix('/activities', {
      PROGRAMME_DASHBOARD: addPrefix('/programme-dashboard/:classroomGroupId', {
        TUTORIAL: addPrefix('/tutorial', {
          GETTING_STARTED: '/getting-started',
          DAILY_ROUTINE: '/daily-routine',
          DEVELOPING_CHILDREN: '/developing-children',
        }),
      }),
    }),
    UPDATE_FEE: '/preschool-fee',
  }),
  TRAINING: '/training',
  COMMUNITY: addPrefix('/community', {
    HELP: '/help/:helpSection',
    WELCOME: '/welcome',
    PROFILE: '/profile',
    CONNECTION_PROFILE: '/connection-profile',
    ECD_HEROES_LIST: '/ecd-heroes-list',
    RECEIVED_REQUESTS: '/received-requests',
    CLUB: addPrefix('/club/:clubId', {
      ADD: '/add',
      EDIT: '/edit',
      MEMBER: addPrefix('/member/:practitionerId', {
        ADD: '/add',
      }),
      MEMBERS: addPrefix('/members', {
        ADD: '/add',
        EDIT: '/edit',
      }),
      LEADER: addPrefix('/leader', {
        ADD: '/add',
        EDIT: '/edit',
      }),
      USER_PROFILE: addPrefix('/user-profile', {
        COACH: '/coach/:coachId',
        LEADER: '/leader/:leaderId',
        SUPPORT_ROLE: '/support-role/:supportRoleId',
        MEMBER: '/member/:practitionerId',
      }),
      POINTS: addPrefix('/points', {
        MEET_REGULARLY: addPrefix('/meet-regularly', {
          MEETING_DETAILS: '/:meetingId/meeting-details',
          MISSING_MEETING_REGISTERS: '/missing-meeting-registers',
        }),
        BE_CREATIVE: '/be-creative',
        HOST_FAMILY_EVENT: '/host-family-event',
        LEAVE_NO_ONE_BEHIND: '/leave-no-one-behind',
        CAPTURE_CHILD_ATTENDANCE: '/capture-child-attendance',
        COMPLETE_CHILD_PROGRESS_REPORTS: '/complete-child-progress-reports',
        HELP: '/help/:helpSection',
      }),
    }),
    LEAGUE: addPrefix('/league/:leagueId', {
      HELP: '/help/:helpSection',
    }),
  }),
  CHILD: addPrefix('/child', {
    INFORMATION: addPrefix('/information', {
      EDIT: '/edit',
    }),
  }),
  PRACTITIONER_PROGRESS_REPORT_SUMMARY: '/progress-summary-report',
  CHILD_REGISTRATION: '/child-registration',
  CHILD_REGISTRATION_BIRTH_CERTIFICATE: '/child-registration-birth-certificate',
  PRINCIPAL: addPrefix('/principal', {
    ADD_PRACTITIONER: '/add-practitioner',
    CONFIRM_PRACTITIONER: 'confirm-practitioner',
    SETUP_PROFILE: '/setup-profile',
    PRACTITIONER_PROFILE: '/practitioner-profile',
    PRACTITIONER_LIST: '/practitioner-list',
    PRACTITIONER_CHILD_LIST: '/practitioner-child-list',
    PRACTITIONER_REASSIGN_CLASS: '/practitioner-reassign-class',
    PRACTITIONER_REMOVE_FROM_PROGRAMME: '/remove-practitioner-from-programme',
    SWAP_PRINCIPAL: '/swap-principal',
    NOTES: '/notes',
    CONTACT_PRACTITIONER: '/contact-practitioner',
  }),
  PRACTITIONER: addPrefix('/practitioner', {
    ABOUT: addPrefix('/about', {
      SIGNATURE: '/signature',
    }),
    ACCOUNT: '/account',
    PROGRAMME_INFORMATION: '/programme-information',
    PROFILE: addPrefix('/profile', {
      PLAYGROUPS: '/playgroups',
      EDIT: '/edit',
    }),
    POINTS: addPrefix('/points', {
      SUMMARY: '/summary',
      YEAR: '/year',
    }),
    CONTACT_COACH: '/contact-coach',
    COMMUNITY: addPrefix('/community', {
      WELCOME: '/welcome',
      ACCEPT_CLUB_LEADER_ROLE: '/accept-club-leader-role',
      CLUB: addPrefix('/club', {
        SUPPORT_ROLE: addPrefix('/support-role', {
          EDIT: '/edit',
        }),
        MEETING: addPrefix('/meeting', {
          ADD_MEETING: addPrefix('/add', {
            UPCOMING_MEETING: '/:eventId',
          }),
        }),
        FAMILY_DAY_EVENT: addPrefix('/family-day-event', {
          ADD_EVENT: '/add',
        }),
        COLLAGE_EVENT: addPrefix('/collage-event', {
          ADD_EVENT: '/add',
        }),
      }),
    }),
  }),
  TRAINEE: addPrefix('/trainee', {
    SETUP_TRAINEE: '/setup-trainee',
    TRAINEE_ONBOARDING: '/trainee-onboarding',
    TRAINEE_ONBOARDING_DASHBOARD: 'trainee-onboarding-dashboard',
  }),
  CHILD_NOTES: '/child-notes',
  CHILD_PROFILE: '/child-profile',
  CHILD_CAREGIVERS: '/child-caregivers',
  CHILD_ATTENDANCE_CAREGIVER: '/child-attendance-caregiver',
  CHILD_ATTENDANCE_REPORT: '/child-attendance-report',
  REMOVE_CHILD: '/remove-child',
  MESSAGES: '/messages',
  PROGRESS_TRACKING_CATEGORY: '/progress-tracking-category',
  PROGRESS_SETUP_REPORTING_PERIODS: '/setup-progress-reporting-period',
  PROGRESS_OBSERVATIONS: '/progress-observations',
  PROGRESS_OBSERVATIONS_LANDING: '/progress-observations-landing',
  PROGRESS_OBSERVATIONS_NOTES: '/progress-observations-notes',
  PROGRESS_REPORT_LIST: '/child-progress-skill-tracking',
  PROGRESS_SELECT_CHILD_TO_TRACK: '/progress-select-child',
  PROGRESS_SELECT_CATEGORY_TO_TRACK: '/progress-select-category',
  PROGRESS_OBSERVATIONS_BY_CATEGORY: '/progress-observations-by-category',
  PROGRESS_CREATE_REPORT: '/progress-create-report',
  PROGRESS_SHARE_REPORT: '/progress-share-report',
  PROGRESS_VIEW_REPORT: '/progress-view-report',
  PROGRESS_VIEW_REPORTS_SUMMARY_SELECT_CLASSROOM_GROUP_AND_AGE_GROUP:
    '/progress-view-reports-summary-select',
  PROGRESS_VIEW_REPORTS_SUMMARY: '/progress-view-reports-summary',
  COACH_REGISTRATION: '/coach-registration',
  COACH_SMARTSPACE_CHECK: '/coach-smart-space-check',
  COACH_TRAINEE_ONBOARDING: '/coach-trainee-onboarding',
  COACH_FRANCHISE_AGREEMENT: '/coach-franchisor-agreement',
  COACH_SELF_ASSESSMENT: '/coach-self-assessment',
  COACH: addPrefix('/coach', {
    ABOUT: addPrefix('/about', {
      SIGNATURE: '/signature',
      ADDRESS: '/address',
    }),
    PRACTITIONERS: '/practitioners',
    PRACTITIONER_PROFILE_INFO: '/practitioner-profile-info',
    PRACTITIONER_REASSIGN_CLASS: '/practitioner-reassign-class',
    PRACTITIONER_JOURNEY: '/practitioner-journey/:practitionerId',
    PRACTITIONER_POINTS: '/practitioner-points/:userId',
    PRACTITIONER_BUSINESS: addPrefix('/practitioner-business', {
      BUSINESS: '/:userId',
      LIST_STATEMENTS: '/:userId/previous-statements-list',
      STATEMENT_DETAILS: '/:userId/statement-details',
    }),
    PRACTITIONER_CLASSROOM: '/practitioner-classroom',
    PRACTITIONER_CHILD_LIST: '/practitioner-childlist',
    PRACTIONER_REMOVE: '/practioner-remove',
    PROGRAMME_INFORMATION: '/programme-information',
    CLASSES_REASSIGNED: '/classes-reassigned',
    CHILD_PROFILE: '/child-profile',
    CONTACT_PRACTITIONER: '/contact-practitioner',
    NOTES: '/notes',
    ACCOUNT: '/account',
    PROFILE: addPrefix('/profile', {
      EDIT: '/edit',
    }),
  }),
  SMART_STARTER_JOURNEY: addPrefix('/smart-starter-journey/:clientId', {}),
};

export default ROUTES;
