import { Options } from '../index.types';

export const options: Options = {
  question1: [
    'The venue has enough clean, safe water for children to drink.',
    'The venue has a safe, clean and hygienic place for children to go to the toilet.',
    'There is a tap, a tippy-tap, a water dispenser or similar for handwashing under clean running water with measures that allow for physical distancing as appropriate.',
    'Medicines and harmful substances are out of reach of children.',
    'Children cannot reach matches, lighters or paraffin.',
    'Children cannot reach or step on sharp objects or other dangerous objects.',
    'Children cannot reach hot cooker plates or pans on the cooker.',
    'There is open water (where children could fall and drown).',
    'There are no exposed electrical wires or electric sockets that children can reach.',
    'There is no smoking or open fires in the venue.',
    'There are no heights or steps from which children could fall.',
    'No dangerous animals can approach the venue.',
    'If children use an outdoor area, it is clean, with no litter or animal faeces.',
    'The venue is in an area that is known as a safe place in the community.',
    'There is at minimum a bucket of sand available in case of fires or the fire blanket or extinguisher.',
    'There is a basic first aid kit in case of accidents.',
    'There is an emergency plan displayed on the wall (can use one from Start pack).',
  ],
};
