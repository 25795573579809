export const contentWrapper = 'h-full overflow-y-auto bg-white';

export const avatarWrapper = 'w-full inline-flex justify-center pt-8';
export const chipsWrapper =
  'w-full mt-10 flex flex-row flex-wrap justify-center items-center gap-4 px-2';
export const notificationsStacklist = 'bg-white mt-4 mb-4';
export const profileOptionsWrapper = 'w-full px-4 pb-4';
export const button = 'w-full mt-4';
export const buttonIcon = 'w-5 h-5 ml-2';

export const dialogContent = 'h-full overflow-y-hidden';
export const absentWrapper =
  'mt-6 w-11/12 h-24 flex items-center bg-alertBg justify-center mr-auto ml-auto rounded-lg';
export const listItem =
  'w-11/12 mt-2 mx-auto z-10 px-3.5 py-2.5 bg-white text-textMid rounded-lg bg-uiBg';
export const listItemFirst =
  'w-11/12 mt-6 mx-auto z-10 px-3.5 py-3 bg-white text-textMid rounded-lg bg-uiBg';
export const fadButton = 'm-3 bottom-14 z-10 px-3.5 py-2.5';
export const buttonIconStyle = 'h-5 w-5 text-primary';
export const infoWrapper =
  'mt-2 w-11/12 mx-auto flex justify-between items-center';
export const contactButtons = 'flex justify-center mt-4 gap-3';
export const rightArrowIcon = 'h-8 w-8';
export const circleIcon = 'h-5 w-5 bg-trasparent text-white';
export const circleIconDiv =
  'h-12 w-12 rounded-full bg-secondary grid place-items-center mr-2';
export const absentCard = 'w-11/12 bg-uiBg rounded-xl mt-4';
export const absentCardTitle = 'mt-6 ml-4';
export const absentCardSubTitle = 'mt-4 ml-4';

export const removalCard = 'w-11/12 bg-errorBg rounded-xl mt-4';
export const removalCardTitle = 'mt-6 ml-4 text-errorMain';
