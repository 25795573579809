import { Options } from '../index.types';

export const options: Options = {
  question1: [
    '0 - The SmartStart routine is not displayed and children do not refer to the names of the different parts of the routine.',
    '1 - The SmartStart routine is displayed so adults can see it, but the children are unable to.  Adults and children sometimes refer to the names of the different parts of the routine.',
    '2 - The SmartStart routine is displayed for everyone to see and in a form understandable to children (e.g. pictures or symbols).  Adults and children often refer to the names of parts of the routine.',
  ],
  question2: [
    '0 - No time was set aside for small group activities with the children.',
    '1 - The small group activity was too short (less than 10 mins) or only some children had an opportunity to join in.',
    '2 - The small group activity lasted for more than 10 mins and all the children participated.',
  ],
  question3: [
    '0 - There was no time set aside for children to make plans and/or to indicate their plans to adults.',
    '1 - Only some children had the chance to make plans and/or to indicate their plans to adults.',
    '2 - All children had the chance to make plans and/or to indicate their plans to adults.',
  ],
  question4: [
    '0 - There was no time to set aside for free play - when children choose or initiate activities.',
    '1 - There was some time set aside for free play - when children choose or initiate activities and can carry out their plans (30-45 mins).',
    '2 - There was substantial time set aside for free play - when children choose or initiate activities and can carry out their plans (at least 45-55 mins).',
  ],
  question5: [
    '0 - There was no time set aside for children to recall or reflect on their activities.',
    '1 - There was some time set aside for children to recall or reflect on their activities, but not all children had the chance to participate.',
    '2 - There was enough time set aside for all the children to recall and reflect on their activities.',
  ],
  question6: [
    '0 - There was no time set aside for story time.',
    '1 - There was a short time set aside for story time (less than 15 mins).',
    '2 - There was a substantial time set aside for story time (at least 15-20 mins).',
  ],
  question7: [
    '0 - There was no time set aside for large group activity.',
    '1 - The large group activity was too short (less than 10 minutes).',
    '2 - The large group activity lasted for more than 10 minutes.',
  ],
};
