import { Options } from '../../coach-self-assessment.types';

export const options: Options = {
  question1: [
    'Greeting time',
    'Message board',
    'Small group activity',
    'Planning time',
    'Play time',
    'Clean up time',
    'Recall time',
    'Large group activity',
    'Story time',
    'Outside time',
    'None',
  ],
};
